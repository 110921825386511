export const environment = {
  production: true,
  reCaptchaKey: '6LeSdiQUAAAAABXEJuEp8Q9kZxIv0MHlKNzyXLjp',
  baseUrl: 'https://beta-website-firebase.web.app/api',
  siteUrl: 'www.ashutec.com',
  // baseHrmsUrl:'https://dev-ashutec-hrms.web.app/api',
  // baseUrl: 'http://localhost:5001/beta-website-firebase/us-central1/api',
  gtagUrl: '',
  firebaseConfig: {
    apiKey: 'AIzaSyCUhwb-n2Xed_6avPS12ht-wzHkVgr8SDI',
    authDomain: 'beta-website-firebase.firebaseapp.com',
    projectId: 'beta-website-firebase',
    storageBucket: 'beta-website-firebase.appspot.com',
    messagingSenderId: '210138715265',
    appId: '1:210138715265:web:f83dbbe9661c6813b26980',
    measurementId: 'G-D0KNT972VQ',
  },
};
