import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ISeo } from 'shared/models';

@Injectable({
  providedIn: 'root',
})
export class MetaService {
  constructor(
    private meta: Meta,
    private titleService: Title,
  ) {}

  public updateMetaTags(seo: ISeo) {
    this.titleService.setTitle(seo.title);
    const metaTags = [];
    if (seo.image) {
      metaTags.push({
        property: 'og:image',
        content: seo.image.url,
      });
      metaTags.push({
        name: 'twitter:image',
        content: seo.image.url,
      });
    }

    if (seo.metaTags) {
      for (const tag of seo.metaTags) {
        if (tag.key.startsWith('og')) {
          this.meta.updateTag({
            property: tag.key,
            content: tag.value,
          });
        } else {
          this.meta.updateTag({
            name: tag.key,
            content: tag.value,
          });
        }
      }
    }
  }
}
