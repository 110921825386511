import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  IAboutUsPage,
  IBlogPage,
  IBlogsPage,
  ICareerPage,
  IContactPage,
  IHomePage,
  IPortfolioPage,
  IPortfoliosPage,
  IPrivacyPage,
  IServicePage,
  IServicesPage,
  ISocialSection,
  ITermsPage,
  ITestimonialPage,
} from 'shared/models';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ContentService {
  constructor(private httpClient: HttpClient) {}

  public getHome() {
    const url = `${environment.baseUrl}/content/home`;
    return this.httpClient.get<IHomePage>(url);
  }

  public getServices() {
    const url = `${environment.baseUrl}/content/services`;
    return this.httpClient.get<IServicesPage>(url);
  }

  public getServiceByUid(uid: string) {
    const url = `${environment.baseUrl}/content/services/${uid}`;
    return this.httpClient.get<IServicePage>(url);
  }

  public getPortfolios() {
    const url = `${environment.baseUrl}/content/portfolios`;
    return this.httpClient.get<IPortfoliosPage>(url);
  }

  public getPortfolioByUid(uid: string) {
    const url = `${environment.baseUrl}/content/portfolios/${uid}`;
    return this.httpClient.get<IPortfolioPage>(url);
  }

  public getAbout() {
    const url = `${environment.baseUrl}/content/about`;
    return this.httpClient.get<IAboutUsPage>(url);
  }

  public getBlogs(page: number, pageSize: number = 10) {
    const url = `${environment.baseUrl}/content/blogs`;
    return this.httpClient.get<IBlogsPage>(url, { params: { page, pageSize } });
  }

  public getBlogByUid(uid: string) {
    const url = `${environment.baseUrl}/content/blogs/${uid}`;
    return this.httpClient.get<IBlogPage>(url);
  }

  public getTestimonials() {
    const url = `${environment.baseUrl}/content/testimonials`;
    return this.httpClient.get<ITestimonialPage>(url);
  }

  public getCareer() {
    const url = `${environment.baseUrl}/content/career`;
    return this.httpClient.get<ICareerPage>(url);
  }

  public getContact() {
    const url = `${environment.baseUrl}/content/contact`;
    return this.httpClient.get<IContactPage>(url);
  }

  public getPrivacy() {
    const url = `${environment.baseUrl}/content/privacy`;
    return this.httpClient.get<IPrivacyPage>(url);
  }

  public getTerms() {
    const url = `${environment.baseUrl}/content/terms`;
    return this.httpClient.get<ITermsPage>(url);
  }

  public getSocial() {
    const url = `${environment.baseUrl}/content/social`;
    return this.httpClient.get<ISocialSection>(url);
  }
}
